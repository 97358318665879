*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
}
html, body, * {
  -webkit-user-drag: none;

}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-40%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
 .fade-in {
  opacity: 0;
  animation: fadeIn 1.5s forwards;
}

.fade-in-right {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-in-visible {
  opacity: 1;
  transform: translateX(0);
}


